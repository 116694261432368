<template>
  <div class="info" id="watermark">
    <top-bar :title="'社区党建'" :left="true"></top-bar>
    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">党员信息</span>
        </template>
      </van-cell>
      <van-field
        clearable
        clear-trigger="always"
        v-model="detailData.idNumber"
        name="用户证件号"
        label="用户证件号"
        placeholder="用户证件号"
        :readonly = isEdit
        @change="getUserByIdNumber()"
        :rules="[{ required: true, message: '请填写用户证件号' }]"
      />
      <van-field
        :value-class="{'value-common':!detailData.branchName}"
        :is-link="isEdit"
        :readonly="isEdit"
        clickable
        name="所属支部"
        :value="detailData.branchName"
        label="所属支部"
        placeholder="点击选择所属支部"
        @click="showPicker = !isEdit"
        :rules="[{ required: true, message: '请填写所属支部' }]"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="partyRelationList"
          value-key="name"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
        :value-class="{'value-common':!detailData.postName}"
        :is-link="isEdit"
        :readonly="isEdit"
        clickable
        name="所属职务"
        :value="detailData.postName"
        label="所属职务"
        placeholder="点击选择所属职务"
        @click="showPicker1 = !isEdit"
        :rules="[{ required: true, message: '请填写所属职务' }]"
      />
      <van-popup v-model="showPicker1"  position="bottom">
        <van-picker
          show-toolbar
          :columns="postList"
          value-key="label"
          @confirm="onConfirmPost"
          @cancel="showPicker1 = false"
        />
      </van-popup>
      <van-field
          :value-class="{'value-common':!detailData.joinBranchDate}"
          :is-link="isEdit"
          :readonly="isEdit"
          clickable
          name="入支日期"
          :value="detailData.joinBranchDate"
          label="入支日期"
          @click="showDatePicker2 = !isEdit"
      />
      <van-popup v-model="showDatePicker2" position="bottom">
        <van-datetime-picker
            type="date"
            @confirm="onConfirmDate2"
            @cancel="showDatePicker2 = false"
        />
      </van-popup>
      <van-field
        :value-class="{'value-common':!detailData.joinDate}"
        :is-link="isEdit"
        :readonly="isEdit"
        clickable
        name="入党日期"
        :value="detailData.joinDate"
        label="入党日期"
        v-show="detailData.partyType == 1"
        @click="showDatePicker = !isEdit"
        :rules="[{ required: true, message: '请填写入党日期' }]"
      />
      <van-popup v-model="showDatePicker" position="bottom">
        <van-datetime-picker
          type="date"
          @confirm="onConfirmDate"
          @cancel="showDatePicker = false"
        />
      </van-popup>
      <van-field
        :value-class="{'value-common':!detailData.correctionDate}"
        :is-link="isEdit"
        v-model="detailData.correctionDate"
        name="转正日期"
        label="转正日期"
        placeholder="转正日期"
        :readonly = isEdit
        @click="showDatePicker1 = !isEdit"
        v-show="detailData.partyType == 2"
        clickable
        :rules="[{ required: true, message: '请填写转正日期' }]"
      />
      <van-popup v-model="showDatePicker1" position="bottom">
        <van-datetime-picker
          type="date"
          @confirm="onConfirmDate1"
          @cancel="showDatePicker1 = false"
        />
      </van-popup>
      <van-field name="流动党员" label="流动党员" input-align="right"  :readonly = isEdit >
        <template #input>
          <van-switch v-model="isFlowStr" size="20" />
        </template>
      </van-field>
      <van-field
        :value-class="{'value-common':!detailData.partyType}"
        :is-link="isEdit"
        :readonly="isEdit"
        clickable
        name="党员类型"
        :value="detailData.partyType==2?'预备党员':'正式党员'"
        label="党员类型"
        placeholder="点击选择党员类型"
        @click="showPartyType = !isEdit"
      />
      <van-popup v-model="showPartyType"  position="bottom">
        <van-picker
          show-toolbar
          :columns="partyType"
          value-key="label"
          @confirm="onConfirmPartyType"
          @cancel="showPartyType = false"
        />
      </van-popup>
    </van-cell-group>

    <van-cell-group class="info-second">
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">个人信息</span>
        </template>
      </van-cell>
      <div class="list-info">
        <div class="cont">
          <div class="list-item">
            <div class="title">
              <div class="title-left">
                {{detailData.educationStr||'学历未知'}}
              </div>
              <div class="title-right" style="color:#387FF5" @click="getInfo(detailData.id)">
                查看更多
              </div>
            </div>
            <div class="content" style="height: 120px;">
              <img :src="userInfo.sex==1?manHeadImg:womanHeadImg" class="content-img">
              <div class="content-text">
                <div class="top-text">{{detailData.userName}}/{{userInfo.age}}/{{userInfo.sex==1?"男":"女"}}</div>
                <div class="bottom-text">{{detailData.mobile}}</div>
                <div class="bottom-text">{{detailData.idNumber}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
          </div>
        </div>
      </div>
    </van-cell-group>

    <van-row class="btns">
      <van-col :span="24" v-if="!isEdit">
        <van-button type="info" size="large" round @click="onSubmit">完成</van-button>
      </van-col>
      <van-row gutter="10" v-else>
        <van-col :span="8">
          <van-button plain type="danger" size="large" round @click="moveOutParty('moveOut')" style="background-color:transparent">迁出</van-button>
        </van-col>
        <van-col :span="8">
          <van-button plain type="danger" size="large" round @click="moveOutParty('delete')" style="background-color:transparent">删除</van-button>
        </van-col>
        <van-col :span="8">
          <van-button type="info" size="large" round @click="getEdit">编辑</van-button>
        </van-col>
      </van-row>
    </van-row>

  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {getImageStream} from '@/utils/index'

import { mapState } from 'vuex'
import {formatterDate} from "@/utils/utils";
export default {
  name: "party-info",
  data () {
    return {
      value: '',
      detailData: {
        isFlow:1,
        joinBranchDate: ''
      },
      isFlowStr:false,
      fileList:[],
      isEdit:true,
      partyRelationList:[],
      showPicker:false,
      postList:[],
      showPicker1:false,
      showDatePicker:false,
      showDatePicker1:false,
      showDatePicker2:false,
      showPartyType:false,
      userInfo:{},
      partyType:[{
        label: '正式党员',
        value: '1',
      },
        {
          label: '预备党员',
          value: '2',
        }]

    }
  },
  computed:{
    ...mapState(['partyId']),
    manHeadImg () {
      return getImageStream('files/wx/images/content/headImgMan.png')
    },
    womanHeadImg () {
      return getImageStream('files/wx/images/content/headImgWoman.png')
    }
  },
  components :{
    topBar
  },
  methods: {
    getDetail () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/party/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.partyId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.detailData = data.appParty;
          this.isFlowStr = this.detailData.isFlow == 1 ? true : false;
          this.getUserByIdNumber();
          this.$toast.clear()
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    onSubmit(values){
      this.$delete(this.detailData,'orgIds')
      if(this.isFlowStr){
        this.detailData.isFlow = 1;
      }else {
        this.detailData.isFlow = 0;
      }
      if (this.userInfo.id != null && this.userInfo.id != 0){
        this.$http({
          url: this.$http.adornUrl('/wxapp/party/update'),
          method: 'post',
          // postparams: this.$http.adornParams({
          //   ...this.detailData
          // })
          data: this.$http.adornData(this.detailData)
        }).then(({data})=> {
          if (data.code == 0) {
            this.isEdit = true;
            this.$toast.clear()
            this.$toast.success("修改成功!");
          } else {
            console.log(data);
            this.isEdit = true;
            this.$toast.clear()
            this.$toast.fail(data.msg);
          }
        })
      }else {
        this.isEdit = true;
        this.$toast.clear()
        this.$toast.fail("居民不存在");
      }

    },
    getEdit(){
      this.isEdit = false;
      //调用方法 获取党支部列表/党员职务列表
      this.getpartyRelation();
      this.getpartyPost();
    },
    onConfirm(value) {
      this.detailData.branchName = value.name;
      this.detailData.branchId = value.id;
      this.showPicker = false;
    },
    onConfirmPost(value) {
      this.detailData.postName = value.label;
      this.detailData.post = value.value;
      this.showPicker1 = false;
    },
    onConfirmDate(value) {
      this.detailData.joinDate = formatterDate(value);
      this.showDatePicker = false;
    },
    onConfirmDate1(value) {
      this.detailData.correctionDate = formatterDate(value);
      this.showDatePicker1 = false;
    },
    onConfirmDate2(value) {
      debugger
      this.detailData.joinBranchDate = formatterDate(value);
      this.showDatePicker2 = false;
    },
    onConfirmPartyType(value) {
      this.detailData.partyType = value.value;
      this.showPartyType = false;
    },
    getpartyRelation(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/partyRelation/list'),
        method: 'post',
        params: this.$http.adornParams({
          community: this.$orgId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          let list = data.page.list
          this.partyRelationList =list;
          this.$toast.clear()
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    getpartyPost(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: 'partyPost',
          orgId: this.$orgId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          let list = data.dicts
          this.postList =list;
          this.$toast.clear()
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    } ,
    getUserByIdNumber(){
      let temp = this.detailData.idNumber;
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/userByIdNumber'),
        method: 'get',
        params: this.$http.adornParams({
          idNumber: this.detailData.idNumber
        })
      }).then(({data})=> {
        if (data.code == 0) {
          if (data.userInfoList!= null && data.userInfoList.length != 0){
            this.userInfo.age = data.userInfoList[0].age;
            this.userInfo.sex = data.userInfoList[0].sex;
            this.userInfo.id = data.userInfoList[0].id
          }else{
            this.isEdit = true;
            this.$toast.clear()
            this.$toast.fail("没有该居民");

          }
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    moveOutParty(url){
      this.$http({
        url: this.$http.adornUrl(`/wxapp/party/${url}`),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId || 29,
          id: url == 'moveOut' ? '' : this.detailData.id,
          relationId: url == 'moveOut' ? this.detailData.relationId : '',
          userId: url == 'moveOut' ? this.detailData.userId : ''
        })
      }).then(({data})=> {
        if (data.code == 0) {
          let list = data.dicts
          this.postList =list;
          this.$toast.clear()
          this.$toast.success("操作成功!");
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
  },
  created () {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    document.querySelector('html').style.backgroundColor = '#fff'
    this.getDetail();

  },
  beforeDestroy () {
    document.querySelector('html').style.backgroundColor = ''
  },

}
</script>
